@import 'src/styles/mixins';
@import 'src/styles/variables';

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 100px 24px;

  @include vp-435 {
    padding: 50px 24px;
  }
}

.dreamchik {
  margin-bottom: 28px;
  width: 200px;
  height: 186px;
  object-fit: cover;
}

.title {
  color: $black;
  @include text(36px, 800, 38px);
  margin-bottom: 20px;
  max-width: 620px;

  @include vp-435 {
    @include text(24px, 800, 28px);
  }
}

.desc {
  color: $black;
  @include text(16px, 400, 22px);
  max-width: 400px;
}

.sign {
  margin: 0 auto 24px;
  width: 120px;
  height: 120px;
  background-color: $primary;
  color: $white;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @include text(36px, 700, 36px);
}

.buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

button.main {
  @include text(14px, 400, 14px);
  padding: 40px;
  max-height: fit-content;
}
